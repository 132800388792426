import { User } from 'firebase/auth';
import { getStorage, ref as storageRef, listAll, StorageReference } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';

import VideoCard from './VideoCard';
import UploadCard from './UploadCard';

// const people = [] || [
//   {
//     name: 'Jane Cooper',
//     title: 'Paradigm Representative',
//     role: 'Admin',
//     email: 'janecooper@example.com',
//     telephone: '+1-202-555-0170',
//     imageUrl:
//       'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
//   },
//   {
//     name: 'Jane Cooper',
//     title: 'Paradigm Representative',
//     role: 'Admin',
//     email: 'janecooper1@example.com',
//     telephone: '+1-202-555-0170',
//     imageUrl:
//       'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
//   },
//   {
//     name: 'Jane Cooper',
//     title: 'Paradigm Representative',
//     role: 'Admin',
//     email: 'janecooper2@example.com',
//     telephone: '+1-202-555-0170',
//     imageUrl:
//       'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
//   },
//   // More people...
// ];

export default function VideoUploads(_props: { user: User }) {
  const [videos, setVideos] = useState<{ date: number; storageRef: StorageReference }[]>([]);

  async function refresh() {
    const storage = getStorage();
    const { currentUser } = getAuth();
    const dirRef = storageRef(storage, `${currentUser!.uid}/thumbs`);
    const list = await listAll(dirRef);
    const items = list.items.map(i => {
      const [datePrefix] = i.name.split('_', 2);
      const date = new Date(Number(datePrefix)).getTime();
      return { date, storageRef: i };
    });
    setVideos(items.sort((a, b) => b.date - a.date));
  }

  useEffect(() => {
    refresh();
  }, [setVideos]);

  const isFirst = videos?.length === 0;

  return (
    <div className="border-solid border-2 rounded-md border-slate-200 bg-slate-50 p-10">
      {videos && (
        <>
          <h1 className="text-2xl tracking-tight font-normal text-gray-900 sm:text-3xl md:text-4xl">
            {isFirst ? 'Upload your first Video!' : 'Here are your Uploaded Videos'}
          </h1>
          <h3 className="font-normal text-indigo">The more you upload, the bigger your chances of winning</h3>
          <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10">
            <UploadCard isFirst={videos.length === 0} onUploadCompleted={refresh} />
            {videos.map(video => (
              <VideoCard key={video.storageRef.fullPath} video={video.storageRef} />
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
