// mport { Spin } from '@heroicons/react/solid';
// import { getAuth } from 'firebase/auth';
import { getDownloadURL, getStorage, ref, StorageReference } from 'firebase/storage';
import { useState, useEffect } from 'react';

export default function VideoCard({ video }: { video: StorageReference }) {
  const [dateStr] = video.name.split('_');
  const date = new Date(Number(dateStr));
  const videoName = video.name.replace('.png', '').replace(`${dateStr}_`, '');
  const [url, setUrl] = useState<string>();
  useEffect(() => {
    (async () => {
      const storage = getStorage();
      const dlUrl = await getDownloadURL(ref(storage, video.fullPath));
      setUrl(dlUrl);
    })();
  }, [video]);
  return (
    <li className="relative col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
      <div className="flex-1 flex flex-col p-8">
        <img className="w-32 h-32 flex-shrink-0 mx-auto" src={url} title={videoName} alt={videoName} />
        <h3 className="mt-6 text-gray-900 text-sm font-medium">{date.toISOString()}</h3>
        <dl className="mt-1 flex-grow flex flex-col justify-between">
          {/* <dt className="sr-only">Title</dt> */}
          <dd className="text-gray-500 text-sm">{videoName}</dd>

          {/* <dt className="sr-only">Role</dt>
          <dd className="mt-3">
            <span className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
              {person.role}
            </span>
          </dd> */}
        </dl>
      </div>
      {/* <div className="h-full w-full absolute">
        <div className="bg-gray-500 opacity-30 h-full w-full absolute" />
        <div className="flex h-full w-full items-center justify-center absolute">
          <div className="animate-pulse inline-block w-40 h-40 bg-indigo-500 rounded-full opacity-0" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div> */}
      {/* <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="w-0 flex-1 flex">
            <a
              href={`mailto:${person.email}`}
              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
            >
              <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span className="ml-3">Email</span>
            </a>
          </div>
          <div className="-ml-px w-0 flex-1 flex">
            <a
              href={`tel:${person.telephone}`}
              className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
            >
              <PhoneIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span className="ml-3">Call</span>
            </a>
          </div>
        </div> 
      </div>*/}
    </li>
  );
}
