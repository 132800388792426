import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { User } from 'firebase/auth';
import jsGravatar from 'js-gravatar';
import { Fragment } from 'react';

export default function UserMenu(props: { user: User; onLogin: () => void; onLogout: () => void }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 focus:outline-none">
          <img
            className="inline-block h-8 w-8 rounded-full"
            src={jsGravatar({
              email: props.user.email!,
            })}
            alt=""
          />
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">
          <div className="px-4 py-3">
            <p className="text-sm">Signed in as</p>
            <p className="text-sm font-medium text-gray-900 truncate">{props.user.email}</p>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={`block px-4 py-2 text-sm${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}
                >
                  Your Uploads
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}block px-4 py-2 text-sm`}
                >
                  Support
                </a>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={props.onLogout}
                  className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}block px-4 py-2 text-sm`}
                >
                  Sign Out
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
