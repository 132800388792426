import { User } from 'firebase/auth';
import { ReactNode } from 'react';

import { BackgroundImages } from './BackgroundImages';
import { Hero } from './Hero';
import PromptBellowImage from './PromptBellowImage';
import { TopBar } from './TopBar';

export default function Shell(props: { user?: User; onLogin: () => void; onLogout: () => void; children: ReactNode }) {
  return (
    <div className="bg-gray-50">
      <div className="relative overflow-hidden">
        <BackgroundImages />

        <div className="relative pt-6 pb-16 sm:pb-24">
          <TopBar user={props.user} onLogin={props.onLogin} onLogout={props.onLogout} />
          <Hero />
        </div>
        <div className="relative">
          <div className="absolute inset-0 flex flex-col" aria-hidden="true">
            <div className="flex-1" />
            <div className="flex-1 w-full bg-gray-800" />
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6">{props.children}</div>
        </div>
      </div>
      <PromptBellowImage />
    </div>
  );
}
