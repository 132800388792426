/* eslint-disable no-console */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { getStorage, ref as storageRef, uploadBytes } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

function Plus() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-32 h-32 flex-shrink-0 mx-auto fill-indigo-600"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default function UploadCard({ isFirst, onUploadCompleted }: any) {
  const [uploadInfo, setUploadInfo] = useState<{ videoDataUrl: string; droppedFile: File; uploaded?: boolean }>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const userId = useMemo(() => {
    const auth = getAuth();
    return auth.currentUser!.uid;
  }, []);

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (rejectedFiles?.length > 0) {
      alert('File cannot be uploaded. Please upload video files.');
      return;
    }

    const file = acceptedFiles[0];
    const reader = new FileReader();

    console.log('on drop');
    // debugger;
    if (file.name.toLocaleLowerCase().endsWith('.mov')) {
      setUploadInfo({
        videoDataUrl: '/no-preview.mp4',
        droppedFile: file,
      });
      return;
    }

    reader.onabort = () => console.warn('file reading was aborted');
    reader.onerror = () => console.warn('file reading has failed');
    reader.onload = async () => {
      setUploadInfo({
        videoDataUrl: reader.result as string,
        droppedFile: file,
      });
    };
    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'video/*',
    multiple: false,
    noClick: false,
  });

  function onError() {
    console.error(videoRef.current?.error);
    alert(`Failed to upload.  ${videoRef.current?.error?.code} ${videoRef.current?.error?.message}`);
    setUploadInfo(undefined);
  }

  async function onCanPlay() {
    try {
      const now = Date.now();

      const storage = getStorage();
      const videoFileRef = storageRef(storage, `${userId}/videos/${now}_${uploadInfo!.droppedFile.name}`);
      const videoUploadPromise = uploadBytes(videoFileRef, uploadInfo!.droppedFile!);

      const c = canvasRef.current!;
      const v = videoRef.current!;

      c.width = v.videoWidth;
      c.height = v.videoHeight;

      console.log('drawImage promise');

      await new Promise(res => {
        setTimeout(() => {
          const ctx = c.getContext('2d');
          ctx?.drawImage(v, 0, 0, v.videoWidth, v.videoHeight);
          res(undefined);
        }, 250);
      });

      console.log('thumbBlob promise');

      const thumbBlob = await new Promise<Blob | undefined>((res, rej) => {
        c.toBlob(blob => {
          if (!blob) rej(new Error('Could not get blob from canvas'));
          res(blob!), 'image/png';
        });
      });

      console.log('videoUploadPromise promise');

      await videoUploadPromise;

      const thumbFileRef = storageRef(storage, `${userId}/thumbs/${now}_${uploadInfo!.droppedFile.name}.png`);

      console.log('uploadBytes(thumbFileRef, promise');

      await uploadBytes(thumbFileRef, thumbBlob!);
      setUploadInfo(prev => ({ ...prev!, uploaded: true }));

      setTimeout(() => {
        setUploadInfo(undefined);
      }, 100);

      onUploadCompleted();
    } catch (e) {
      setUploadInfo(undefined);
      alert('Failed to upload.');
      console.error(e);
    }
  }

  console.log({ uploadInfo });

  return (
    <li
      {...getRootProps()}
      className={`${
        uploadInfo && !uploadInfo.uploaded ? '' : 'cursor-pointer '
      } col-span-1 flex flex-col items-center content-center text-center bg-slate-50	rounded-lg  border-2  border-dotted border-indigo-600`}
    >
      {!uploadInfo && <input {...getInputProps()} />}
      <div className="flex-1 flex flex-col p-8">
        <canvas className="hidden" ref={canvasRef!}></canvas>
        {uploadInfo && (
          <>
            <video
              ref={videoRef}
              onCanPlay={onCanPlay}
              onError={onError}
              muted
              className="w-32 h-32 flex-shrink-0 mx-auto"
            >
              <source src={uploadInfo.videoDataUrl}></source>
            </video>
            <h3 className="animate-pulse opacity-0 mt-6 text-black-900 text-sm font-bold">Uploading...</h3>
            <dl className="mt-1 flex-grow flex flex-col justify-between">
              <dd className="text-black-900 text-sm">&nbsp;</dd>
            </dl>
          </>
        )}
        {!uploadInfo && (
          <>
            <Plus />
            <h3 className="mt-6 text-black-900 text-sm font-bold">
              Upload {!isFirst ? 'another' : 'your first'} Video
            </h3>
            <dl className="mt-1 flex-grow flex flex-col justify-between">
              <dd className="text-black-900 text-sm">Drop a video file here or click to browse for one</dd>
            </dl>
          </>
        )}
      </div>
    </li>
  );
}
