import { useEffect, useState } from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup, User } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

import './App.css';
import Shell from './Shell';
import VideoUploads from './VideoUploads';

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyBIzQ_OhNtItuvF6j9pFzOYw5fTrTqN6aI',
  authDomain: 'magic-reviews-c7c8f.firebaseapp.com',
  projectId: 'magic-reviews-c7c8f',
  storageBucket: 'magic-reviews-c7c8f.appspot.com',
  messagingSenderId: '113855960893',
  appId: '1:113855960893:web:2ee0c5fcbe146254029754',
});

const googleProvider = new GoogleAuthProvider();

const firebaseAuth = getAuth(firebaseApp);

export default function App() {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    firebaseAuth.onAuthStateChanged(
      user => {
        setUser(user || undefined);
      },
      error => {
        alert(error.message);
      }
    );
  }, [setUser]);

  const onLogin = () => {
    signInWithPopup(firebaseAuth, googleProvider);
  };
  const onLogout = () => {
    firebaseAuth.signOut();
  };

  return (
    <Shell user={user} onLogin={onLogin} onLogout={onLogout}>
      <div className="relative rounded-lg shadow-lg">
        {user ? (
          <VideoUploads user={user} />
        ) : (
          <div style={{ height: 750, background: 'green' }}>no users so marketing</div>
        )}
      </div>
    </Shell>
  );
}
